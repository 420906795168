<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" centered :title="`Menu Güncelle ( ${form.baslik} )`">
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Alt Kategoriler Menüde Göster">
              <v-select
                v-model="form.kategori"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Sayfaları Menüde Göster">
              <v-select
                v-model="form.sayfa"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Turları Menüde Göster">
              <v-select
                v-model="form.turlar"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Yeni Sekmede Göster">
              <v-select
                v-model="form.sekme"
                :options="durumlar"
                :reduce="(durumlar) => durumlar.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Yeni Sekmede Göster">
              <v-select
                v-model="form.turu"
                :options="menuTurleri"
                :reduce="(turu) => turu.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label for="statu" class="font-weight-bold h6">Seo Optimize</label>
              <b-form-checkbox :checked="form.seo" v-model="form.seo" switch />
            </div>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="handlerMenuGuncelle" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Kaydet
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="handlerMenuSil(form._id)" :disabled="showDel">
            <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" v-if="!showDel" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Sil
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    showDel: false,
    form: {
      _id: null,
      k_no: null,
      baslik: null,
      kategori: 0,
      sayfa: 0,
      turlar: 0,
      sekme: 0,
      turu: 'sayfa',
      seo: false,
    },
    durumlar: [
      {
        id: 0,
        title: 'Hayır',
      },
      {
        id: 1,
        title: 'Evet',
      },
    ],

    menuTurleri: [
      {
        id: 'sayfa',
        title: 'Sayfa',
      },
      {
        id: 'kategori',
        title: 'Kategori',
      },
      {
        id: 'urun',
        title: 'Ürün',
      },
      {
        id: 'blog',
        title: 'Blog',
      },
    ],
  }),
  methods: {
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
      this.$emit('closeModal', true);
    },
    handlerMenuGuncelle() {
      this.show = true;
      this.$store
        .dispatch('menuGuncelle', this.form)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            });
            this.toggleModal();
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.data.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
    handlerMenuSil(_id) {
      this.showDel = true;
      this.$store
        .dispatch('menuSil', _id)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'Trash2Icon',
                variant: 'danger',
                text: 'Silme Başarılı.',
              },
            });
            this.toggleModal();
            this.showDel = false;
          }
        })
        .catch((err) => {
          this.showDel = false;
          if (err.data.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
  watch: {
    openModal: {
      handler(val) {
        if (val == true) {
          this.form._id = this.updateData._id;
          this.form.k_no = this.updateData.k_no;
          this.form.baslik = this.updateData.baslik;
          this.form.kategori = this.updateData.kategori;
          this.form.sayfa = this.updateData.sayfa;
          this.form.turlar = this.updateData.turlar;
          this.form.sekme = this.updateData.sekme;
          this.form.turu = this.updateData.turu;
          this.form.seo = this.updateData.seo;
          this.toggleModal();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
