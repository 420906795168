<template>
  <div>
    <b-card-normal>
      <template v-slot:body>
        <b-list-group class="rounded-0" horizontal="md">
          <b-list-group-item class="rounded-0" disabled>Anasayfa</b-list-group-item>
          <draggable
            v-model="menuler"
            handle=".draggable-task-handle"
            class="list-group list-group-horizontal"
            tag="ul"
            @end="handlerMenuChange"
          >
            <b-list-group-item class="rounded-0" v-for="menu in menuler" :key="menu._id" tag="li">
              <feather-icon class="cursor-move draggable-task-handle mr-25" icon="MoveIcon" size="18" />
              <feather-icon class="cursor-pointer" icon="SettingsIcon" size="18" @click="handlerUpdate(menu)" />
              | {{ menu.baslik }}
            </b-list-group-item>
          </draggable>
          <b-list-group-item class="rounded-0" disabled>İletişim</b-list-group-item>
        </b-list-group>
      </template>
    </b-card-normal>

    <menu-guncelle :updateData="updateData" :openModal="openModal" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import draggable from 'vuedraggable';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import MenuGuncelle from './menuGuncelle.vue';
export default {
  components: {
    BCardNormal,
    draggable,
    MenuGuncelle,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: [],
    },
  },
  data: () => ({
    openModal: false,
    closeModal: false,
    updateData: {},
    drag: false,
    menuler: [],
  }),
  methods: {
    handlerUpdate(event) {
      this.updateData = event;
      this.openModal = true;
    },
    handlerMenuChange() {
      this.$store
        .dispatch('menuSiraGuncelle', this.menuler)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Sira Güncelleme Başarılı.',
              },
            });
            this.$emit('update', this.data.data);
          }
        })
        .catch((err) => {
          if (err.data.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
  watch: {
    closeModal(val) {
      this.openModal = false;
      this.closeModal = false;
      this.updateData = {};
      this.$emit('update', this.updateData);
    },
    data: {
      handler(val) {
        this.menuler = val;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-ozel {
  background-color: #dadada;
}
</style>
